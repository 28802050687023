

export default {
  name: 'Slider',

  computed: {
    slides() {
      return this.slidesData
        // можно раскомментировать, случайным образом перемешивает слайды, закомментировать чтобы начинался с 1-го
         .map((value) => ({ value, sort: Math.random() }))
         .sort((a, b) => a.sort - b.sort)
         .map(({ value }) => value)
      //options.initialSlide = this.getRandomIntInclusive(0, this.slides.length - 1) строчка из раздела ниже
    },
    sliderOptions() {
      let options = {...this.myOptions}
      // можно раскомментировать, чтобы начинался с 1-го
      // options.initialSlide = 0;
      options.initialSlide = this.getRandomIntInclusive(0, this.slides.length - 1)

      return options
    }
  },

  data () {
    return {
      slidesData: [
/*        {
          image: 'ban_gene_synth',
          type: 'jpg',
          href: '/services/ges/gene-synthesis',
          map: '',
          alt: ''
        },*/
        {
          image: 'cleanupstpcr',
          type: 'jpg',
          href: '/products/isolation/cleanup-pcr',
          map: '',
          alt: ''
        },
        {
          image: 'cleanupstgel',
          type: 'jpg',
          href: '/products/isolation/cleanup-gel',
          map: '',
          alt: ''
        },
        {
          image: 'dnasee',
          type: 'jpg',
          href: '/products/isolation/dnase-e',
          map: '',
          alt: ''
        },
        {
          image: 'ban_bisquick',
          type: 'jpg',
          href: '/products/isolation/bisquick',
          map: '',
          alt: ''
        },
        {
          image: 'ban_RiboCare',
          type: 'jpg',
          href: '/products/isolation/ribocare-rnase-inhibitor',
          map: '',
          alt: ''
        },
        {
          image: 'ban_magnusl',
          type: 'jpg',
          href: '/products/cdna/synthesis/magnus',
          map: '',
          alt: ''
        },
        {
          image: 'ban_solo',
          type: 'jpg',
          href: '/products/isolation/rna-solo',
          map: '',
          alt: ''
        },
        {
          image: 'bann2020_13',
          type: 'jpg',
          href: '/products/isolation/cleanmag/magstand',
          map: '',
          alt: ''
        },
/*        {
          image: 'bann2019_172',
          type: 'jpg',
          href: '/products/isolation/cleanmag/magstand',
          map: '#slide1map',
          alt: ''
        },*/
        {
          image: 'bann2019_54',
          type: 'jpg',
          href: '/products/mycoplasma/myco-real-time',
          map: '',
          alt: ''
        },
/*        {
          image: 'bann2017_221',
          type: 'jpg',
          href: '/products/pcr/polymerases/sybr',
          map: '',
          alt: ''
        },*/
        {
          image: 'bann2017_91',
          type: 'jpg',
          href: '/products/isolation',
          map: '',
          alt: ''
        },
/*        {
          image: 'bann2017_52',
          type: 'jpg',
          href: '/products/cdna/synthesis',
          map: '',
          alt: ''
        },*/
      ],
      myOptions: {
        initialSlide: 1,
        navButtons: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: false,
              infinite: true
            }
          },
          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: true
            }
          }
        ]
      }
    }
  },

  methods: {
    getRandomIntInclusive(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
